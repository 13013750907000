/**
 * Internal dependencies
 */
import { useMatchMedia } from '.';
import * as breakpoints from 'styles/config/_breakpoints.module.scss';

const breakpointsOrder = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

const useBreakpoint = () => {
	const matchMedia = breakpointsOrder.map((breakpoint) => ({
		breakpoint,
		matched:
			breakpoint in breakpoints &&
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useMatchMedia(
				`(max-width: ${
					breakpoints[breakpoint as keyof typeof breakpoints]
				})`
			),
	}));

	return matchMedia.find(({ matched }) => !!matched)?.breakpoint;
};

export default useBreakpoint;
