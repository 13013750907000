import { BackgroundText, ClutchReviews, ContentWithLink, Logos, Spacer, Team, Text, WhatWeDo, WordsHero } from "components";
import { team } from "config";
import sofafun from "images/team-images/sofafun.jpg";
import branding from "images/buttons-list/branding.png";
import design from "images/buttons-list/design.png";
import development from "images/buttons-list/development.png";
import fusebox from "images/client-logos/fusebox.svg";
import gpd from "images/client-logos/gpd.svg";
import insanelab from "images/client-logos/insanelab.svg";
import smashing from "images/client-logos/smashing.svg";
import thecamels from "images/client-logos/thecamels.svg";
import tnbt from "images/client-logos/tnbt.svg";
import wpserved from "images/client-logos/wpserved.svg";
import wptavern from "images/client-logos/wptavern.png";
import wupwt from "images/client-logos/wupwt.png";
import * as React from 'react';
export default {
  BackgroundText,
  ClutchReviews,
  ContentWithLink,
  Logos,
  Spacer,
  Team,
  Text,
  WhatWeDo,
  WordsHero,
  team,
  sofafun,
  branding,
  design,
  development,
  fusebox,
  gpd,
  insanelab,
  smashing,
  thecamels,
  tnbt,
  wpserved,
  wptavern,
  wupwt,
  React
};