/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import './HomeHeader.scss';
import ClutchWidget from 'components/ClutchWidget';
import ContentWithLink from '../ContentWithLink';
import sofa from 'images/team-images/sofa.jpg';

type HomeHeaderProps = {};

const HomeHeader: FC<HomeHeaderProps> = () => (
	<div className="home-header">
		<ContentWithLink
			button={{
				children: 'Check our work',
				to: '/about',
			}}
			content="We design, develop and maintain software built on top of WordPress and Laravel. You can grow your business at the same time."
			headline="We do **development** and **design**. And we do it right."
			headlineAlign="left"
			image={{
				src: sofa,
				title: 'Department of Web Team',
			}}
			imagePosition="top"
		/>
		<ClutchWidget />
	</div>
);

export default HomeHeader;
