/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import './ClutchReviews.scss';
import ClutchWidget from 'components/ClutchWidget';

type ClutchReviewsProps = {};

const ClutchReviews: FC<ClutchReviewsProps> = () => (
	<div className="clutch-reviews">
		<ClutchWidget reviews="348696,343956" type={8} height={300} />
	</div>
);

export default ClutchReviews;
