/**
 * External dependencies
 */
import { FC, useEffect } from 'react';
import { filter, isEmpty } from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import { useStore } from 'react-redux';
import Helmet from 'react-helmet';

/**
 * Internal dependencies
 */
import ogImage from 'images/og-image.png?original';
import 'styles/_index.scss';
import {
	ClutchWidget,
	Container,
	Footer,
	FooterCopyright,
	NavBar,
} from 'components';
import { linkToButtonProps, isLinkData } from 'utils';
import { PageProps, SiteMetadataQuery } from 'types';
import { useFooterData, useNavBarData } from 'hooks';
import { setNavBarColor, isNavBarColor } from 'store';
import './Layout.scss';

const Layout: FC<PageProps> = ({ children, pageContext }) => {
	const { site, mdx }: SiteMetadataQuery = useStaticQuery(graphql`
		query SiteMetadata {
			site {
				siteMetadata {
					description
					navBarColor
					siteUrl
					title
				}
			}
			mdx(slug: { eq: "404" }) {
				frontmatter {
					navBarColor
					title
					footer
				}
			}
		}
	`);

	const fm = pageContext.frontmatter || mdx?.frontmatter;

	const { dispatch } = useStore();

	const navBarColor = fm?.navBarColor || site?.siteMetadata?.navBarColor;

	useEffect(() => {
		if (isNavBarColor(navBarColor)) {
			dispatch(setNavBarColor(navBarColor));
		}
	});

	const footerData = useFooterData();
	const navBarData = useNavBarData();

	const siteTitle = site?.siteMetadata?.title || '';

	const titleParts = filter(
		[fm?.title, siteTitle],
		(value) => !isEmpty(value)
	);

	const title = titleParts.join(' - ');
	const description = site?.siteMetadata?.description;

	const hasFooter = fm?.footer !== 'hidden';
	const hasSimpleFooter = fm?.footer === 'simple';

	const copyright = (fm as any).copyright || footerData?.copyright;

	const headerButton = isLinkData(pageContext.frontmatter?.headerButton)
		? pageContext.frontmatter?.headerButton
		: undefined;

	return (
		<>
			<Helmet
				htmlAttributes={{
					lang: 'en',
				}}
			>
				<title>{title}</title>
				<meta
					property="og:image"
					content={site?.siteMetadata?.siteUrl + ogImage}
				/>
				<meta property="og:image:width" content="2400" />
				<meta property="og:image:width" content="1254" />
				{description && (
					<meta name="description" content={description} />
				)}
			</Helmet>
			{navBarData && (
				<NavBar
					backgroundLines={navBarData.backgroundLines}
					items={navBarData.menuItems}
					logo={{
						src: navBarData.logoURL,
						title: siteTitle,
					}}
					menuButtons={navBarData.menuButtons.map((link) =>
						linkToButtonProps(link)
					)}
					topButton={linkToButtonProps(
						headerButton || navBarData.button
					)}
				/>
			)}
			<Container full>{children}</Container>
			{hasFooter && footerData && !hasSimpleFooter && (
				<Footer {...footerData} />
			)}
			{hasSimpleFooter && (
				<Container>
					<FooterCopyright color="dark">{copyright}</FooterCopyright>
				</Container>
			)}
			<div className="clutch-widget-floating">
				<ClutchWidget type={1} height={40} />
			</div>
		</>
	);
};

export default Layout;
