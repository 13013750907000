/**
 * External dependencies
 */
import { FC } from 'react';
import { Script } from 'gatsby';

/**
 * Internal dependencies
 */
import './ClutchWidget.scss';
import classNames from 'classnames';

type ClutchWidgetProps = {
	className?: string;
	height?: string | number;
	reviews?: string;
	type?: string | number;
};

const ClutchWidget: FC<ClutchWidgetProps> = ({
	className,
	height = 50,
	reviews,
	type = 14,
}) => (
	<div className={classNames('clutch-widget-wrap', className)}>
		<Script
			src="https://widget.clutch.co/static/js/widget.js"
			onLoad={() => {
				// @ts-ignore
				window.CLUTCHCO.Init();
			}}
		/>
		<div
			className="clutch-widget"
			data-clutchcompany-id="2458691"
			data-expandifr="true"
			data-height={height}
			data-nofollow="true"
			data-reviews={reviews}
			data-scale="100"
			data-url="https://widget.clutch.co"
			data-widget-type={type}
		/>
	</div>
);

export default ClutchWidget;
