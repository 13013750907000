/**
 * External dependencies
 */
import { FC, useMemo, useState } from 'react';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { PageProps } from 'gatsby';

/**
 * Internal dependencies
 */
import './styles.scss';
import { caseStudiesTags } from 'config';
import { Filters, GridItem } from '../../index';
import Grid from '../../Grid';

type CaseStudy = {
	id: string;
	body: string;
	frontmatter: {
		slug: string;
		title: string;
		date: string;
		image: {
			childImageSharp: {
				gatsbyImageData: IGatsbyImageData;
			};
		};
		image_title: string;
		tagline: string;
		contract: string;
		country: string;
		timeline: string;
		tags: string[];
	};
};

type CaseStudyProps = PageProps<{
	allMdx?: {
		edges: Array<{ node: CaseStudy }>;
	};
}>;

const CaseStudies: FC<CaseStudyProps> = (props) => {
	const [selectedTag, setSelectedTag] =
		useState<keyof typeof caseStudiesTags>('all');

	const items = useMemo(
		() =>
			selectedTag === 'all'
				? props.data?.allMdx?.edges
				: props.data?.allMdx?.edges.filter((study) =>
						study.node.frontmatter.tags.includes(selectedTag)
				  ),
		[props.data?.allMdx?.edges, selectedTag]
	);

	console.log({
		items: items?.map((item) => ({
			title: item.node.frontmatter.title,
			date: item.node.frontmatter.date,
		})),
	});

	return (
		<div className="case-studies">
			<div>
				<h4 className="case-studies-title">Case Studies</h4>
				<div className="case-studies-filters">
					<Filters
						onChange={(value) => setSelectedTag(value)}
						items={caseStudiesTags}
						value={selectedTag}
					/>
				</div>
			</div>
			{items && (
				<Grid>
					{items.map(({ node: study }, key) => {
						const image = getImage(
							study.frontmatter.image.childImageSharp
								?.gatsbyImageData
						);

						return (
							<GridItem
								key={key}
								title={study.frontmatter.title}
								link={study.frontmatter.slug}
								tagline={study.frontmatter.tagline}
								image={{
									image: image,
									title: study.frontmatter.image_title,
								}}
							/>
						);
					})}
				</Grid>
			)}
		</div>
	);
};

export default CaseStudies;
